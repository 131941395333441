.ath-container {
  z-index: 1000;
  width: 100%;
  color: #000;
  text-align: center;
  background-color: #eee;
  flex-direction: column;
  padding: 1%;
  display: none;
  position: fixed;
}

.ath-banner {
  flex-direction: row;
  justify-content: center;
  display: flex;
  position: relative;
}

.ath-banner-title {
  width: calc(100% - 240px);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
}

.ath-banner-cell-2x, .ath-banner-cell {
  width: 70px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  display: flex;
}

.ath-banner-cell-2x {
  width: 120px;
}

.log-target {
  overflow-wrap: break-word;
}

.banner-top-left {
  top: 0;
  left: 0;
}

.banner-top-right {
  top: 0;
  right: 0;
}

.banner-bottom-left {
  bottom: 0;
  left: 0;
}

.banner-bottom-right {
  bottom: 0;
  right: 0;
}

.banner-center {
  top: 25%;
  left: 25%;
}

.banner-center-left {
  top: 25%;
  left: 0;
}

.banner-center-right {
  top: 25%;
  right: 0;
}

.banner-top-center {
  width: 50%;
  top: 12px;
  left: 25%;
}

.banner-bottom-center {
  bottom: 12px;
}

.ath-guidance img {
  position: absolute;
  bottom: 0;
}

@media (min-width: 576px) {
  .banner-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .ath-banner-cell {
    width: 90px;
    margin: 3px;
  }
}

@media (min-width: 768px) {
  .ath-banner-title {
    width: calc(100% - 360px);
  }

  .ath-banner-cell-2x, .ath-banner-cell {
    width: 120px;
  }

  .ath-banner-cell-2x {
    width: 160px;
  }
}

@media (min-width: 992px) {
  .banner-lg {
    width: 50%;
  }

  .banner-lg-top-center {
    top: 12px;
    left: 25%;
  }

  .banner-lg-bottom-center {
    bottom: 12px;
    left: 25%;
  }

  .banner-bottom-center {
    width: 60%;
    left: 20%;
  }

  .ath-container img {
    left: 15%;
  }
}

.edge-wrapper, .firefox-wrapper, .samsung-wrapper, .opera-home-screen-wrapper {
  opacity: 0;
}

.firefox-wrapper, .samsung-wrapper, .opera-home-screen-wrapper {
  bottom: 21px;
}

.opera-home-screen-wrapper {
  width: 100%;
  max-width: 483px;
  left: auto;
  right: 6px;
}

.ipad-wrapper img {
  position: absolute;
  top: 0;
}

.animated.delay-7s {
  animation-delay: 7s;
}

.overlay-1 {
  z-index: 2020;
}

.overlay-2 {
  z-index: 2040;
}

.overlay {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all .3s;
  position: absolute;
  inset: 0;
}

.ath-container p {
  z-index: 2147483642;
  text-shadow: 0 .1em #fff;
  margin: 0;
  padding: 0;
  font-size: 1.1em;
  position: relative;
}

/*# sourceMappingURL=index.3a84e49f.css.map */
